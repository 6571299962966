// Preview.js
import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import {
  FaCaretDown,
  FaCaretUp,
  FaFilePdf,
  FaFileWord,
  FaFileAlt,
} from "react-icons/fa";
import { downloadPDF } from "./pdfUtils";
import "jspdf-autotable";

const Preview = ({ html, onApplyChanges, isDarkMode }) => {
  const editorRef = useRef(null); // Ref for the Jodit editor
  const [content, setContent] = useState(html); // Editor content state
  const [isChanged, setIsChanged] = useState(false); // Track if changes were made
  const [dropdownVisible, setDropdownVisible] = useState(false); // Toggle for download dropdown
  const [wordCount, setWordCount] = useState(0); // Word count state
  const dropdownRef = useRef(null); // Dropdown ref for click outside detection

  // Initialize content and word count on component mount or prop change
  useEffect(() => {
    setContent(html);
    setWordCount(calculateWordCount(html));
  }, [html]);

  // Click outside handler to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update word count dynamically as content changes
  const handleEditorChange = (newContent) => {
    setContent(newContent);
    setWordCount(calculateWordCount(newContent));
    setIsChanged(true);
  };

  // Helper function to calculate word count
  const calculateWordCount = (htmlContent) => {
    const strippedText = htmlContent.replace(/<[^>]+>/g, "").trim(); // Strip HTML tags
    return strippedText.split(/\s+/).filter(Boolean).length;
  };

  // Handle applying changes
  const applyChanges = () => {
    onApplyChanges(content);
    setIsChanged(false);
  };

  // Handle reverting changes
  const revertChanges = () => {
    setContent(html); // Reset to the original HTML
    setIsChanged(false);
  };

  // Download as Word
  const downloadWord = () => {
    const converted = htmlDocx.asBlob(content);
    saveAs(converted, "document.docx");
    setDropdownVisible(false);
  };

  // Download as plain text
  const downloadPlainText = () => {
    const strippedText = content
      .replace(/<[^>]+>/g, "\n")
      .replace(/\n{2,}/g, "\n"); // Remove excessive line breaks
    const blob = new Blob([strippedText], { type: "text/plain" });
    saveAs(blob, "content.txt");
    setDropdownVisible(false);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Jodit editor configuration
  const editorConfig = {
    readonly: false,
    showPoweredBy: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    showCharsCounter: true,
    toolbar: true,
    spellcheck: true,
    language: "en",
    buttons: ["bold", "italic", "underline", "link", "ul", "ol", "image", "hr"],
    toolbarSticky: false,
    toolbarAdaptive: false,
    showWordsCounter: false,
    // Customize the theme based on dark mode
    theme: isDarkMode ? "dark" : "default",
    // Additional configurations can be added here
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {/* Header section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>Preview</h2>
        <div>
          <b>
            {wordCount} {wordCount === 1 ? "word" : "words"}
          </b>
        </div>
        {/* Download dropdown */}
        <div ref={dropdownRef} style={{ position: "relative" }}>
          <button
            onClick={toggleDropdown}
            disabled={!content.trim()}
            style={{
              padding: "8px 16px",
              border: "1px solid #dfe1e6",
              borderRadius: "4px",
              backgroundColor: content.trim() ? "#0052cc" : "#c0c0c0",
              color: "#ffffff",
              fontSize: "14px",
              cursor: content.trim() ? "pointer" : "not-allowed",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
              transition: "background-color 0.3s, box-shadow 0.3s",
            }}
            onMouseEnter={(e) => {
              if (content.trim()) {
                e.currentTarget.style.backgroundColor = "#0066ff";
                e.currentTarget.style.boxShadow =
                  "0 4px 8px rgba(0, 0, 0, 0.1)";
              }
            }}
            onMouseLeave={(e) => {
              if (content.trim()) {
                e.currentTarget.style.backgroundColor = "#0052cc";
                e.currentTarget.style.boxShadow = "none";
              }
            }}
          >
            Download
            {dropdownVisible ? <FaCaretUp /> : <FaCaretDown />}
          </button>
          {dropdownVisible && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                backgroundColor: isDarkMode ? "#2d2d2d" : "#ffffff",
                border: "1px solid #dfe1e6",
                borderRadius: "4px",
                marginTop: "5px",
                zIndex: 2,
                width: "150px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <button
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "none",
                  backgroundColor: isDarkMode ? "#2d2d2d" : "#ffffff",
                  cursor: "pointer",
                  textAlign: "left",
                  color: isDarkMode ? "#66b3ff" : "#0052cc",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onClick={() => downloadPDF(content)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#444444"
                    : "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#2d2d2d"
                    : "#ffffff")
                }
              >
                <FaFilePdf /> PDF
              </button>

              <button
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "none",
                  backgroundColor: isDarkMode ? "#2d2d2d" : "#ffffff",
                  cursor: "pointer",
                  textAlign: "left",
                  color: isDarkMode ? "#66b3ff" : "#0052cc",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onClick={downloadWord}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#444444"
                    : "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#2d2d2d"
                    : "#ffffff")
                }
              >
                <FaFileWord /> Word
              </button>

              <button
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "none",
                  backgroundColor: isDarkMode ? "#2d2d2d" : "#ffffff",
                  cursor: "pointer",
                  textAlign: "left",
                  color: isDarkMode ? "#66b3ff" : "#0052cc",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onClick={downloadPlainText}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#444444"
                    : "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = isDarkMode
                    ? "#2d2d2d"
                    : "#ffffff")
                }
              >
                <FaFileAlt /> Plain Text
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Editor Section */}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto", // Added to make the content scrollable
        }}
      >
        <JoditEditor
          ref={editorRef}
          value={content}
          config={editorConfig}
          onBlur={handleEditorChange} // Update content when editor loses focus
          style={{ minHeight: "100%" }} // Adjusted from 'height' to 'minHeight'
        />
      </div>

      {/* Footer: Apply and Revert buttons */}
      {isChanged && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <button
            onClick={revertChanges}
            style={{
              marginRight: "10px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: isDarkMode ? "#444444" : "#f0f0f0",
              color: isDarkMode ? "#ffffff" : "black",
              fontSize: "14px",
              cursor: "pointer",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = isDarkMode
                ? "#555555"
                : "#e0e0e0")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = isDarkMode
                ? "#444444"
                : "#f0f0f0")
            }
          >
            Revert
          </button>
          <button
            onClick={applyChanges}
            style={{
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#0052cc",
              color: "white",
              fontSize: "14px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#0066ff")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#0052cc")
            }
          >
            Apply Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default Preview;
