// App.js
import React, { useState, useEffect } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // Importing react-toggle CSS
import { markdownToHTML, htmlToMarkdown } from "./Components/utils";
import { FaCheck } from "react-icons/fa6";

// Components 
import Editor from "./Components/Editor";
import Preview from "./Components/Preview";
import SideNav from "./Components/SideNav";

// App style
import "./App.css";

const App = () => {
  // Existing states
  const [markdown, setMarkdown] = useState("");
  const [htmlPreview, setHtmlPreview] = useState("");
  const [buttonText, setButtonText] = useState("Clear");

  // New theme state with persistence
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  // Toggle theme function with persistence
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      localStorage.setItem("isDarkMode", JSON.stringify(!prevMode));
      return !prevMode;
    });
  };

  // Handle markdown changes and update the HTML preview
  const handleMarkdownChange = (newMarkdown) => {
    setMarkdown(newMarkdown);
    setHtmlPreview(markdownToHTML(newMarkdown)); // Convert markdown to HTML
  };

  // Handle applying changes from the Preview back to the Editor
  const handleApplyChanges = (newHtml) => {
    const newMarkdown = htmlToMarkdown(newHtml); // Convert HTML back to markdown
    setMarkdown(newMarkdown); // Update editor with the new markdown
  };

  // Clear text function
  const clearText = () => {
    setMarkdown("");
    setHtmlPreview("");
    setButtonText(<FaCheck fontSize={"14px"} />);
    setTimeout(() => setButtonText("Clear"), 1000); // Reset button text after 1 second
  };

  // Optional: Apply dark mode class to body for global styles
  useEffect(() => {
    document.body.className = isDarkMode ? "dark-mode" : "light-mode";
  }, [isDarkMode]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        backgroundColor: isDarkMode ? "#1e1e1e" : "#f4f5f7",
        color: isDarkMode ? "#ffffff" : "#000000",
        transition: "background-color 0.3s, color 0.3s",
      }}
    >
      {/* Side Navigation */}
      <SideNav isDarkMode={isDarkMode} />

      {/* Main Content Area */}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {/* Header */}
        <header
          style={{
            backgroundColor: "#0052CC", // Fixed color regardless of dark mode
            color: "white",
            padding: "1em",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          {/* Left Spacer to Center the Title */}
          <div style={{ width: "50px" }}></div>

          {/* Title */}
          <h1 style={{ margin: 0, fontFamily: "UnifrakturCook, cursive" }}>
            MD Editor
          </h1>

          {/* Dark Mode Toggle Switch */}
          <div className="toggle-switch-container">
            <label htmlFor="darkModeToggle" className="toggle-label">
              <Toggle
                id="darkModeToggle"
                checked={isDarkMode}
                onChange={toggleDarkMode}
                // icons={{
                //   checked: <span style={{ color: "white", fontSize: "12px" }}>🌙</span>,
                //   unchecked: <span style={{ color: "white", fontSize: "12px" }}>☀️</span>,
                // }}

                icons={false}
                aria-label="Toggle Dark Mode"
              />
            </label>
          </div>
        </header>

        {/* Content Area */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* Editor and Preview Sections */}
          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "1em",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            {/* Editor Section */}
            <div
              style={{
                flex: 1,
                marginRight: "1em",
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  backgroundColor: isDarkMode ? "#2d2d2d" : "white",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "1em",
                  position: "relative",
                  flex: 1,
                  overflow: "hidden",
                  transition: "background-color 0.3s",
                }}
              >
                <h2 style={{ margin: "0 0 10px" }}>Editor</h2>
                {/* Clear Button */}
                <button
                  onClick={clearText}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "15px",
                    backgroundColor: isDarkMode ? "#444444" : "#f0f0f0",
                    color: isDarkMode ? "#ffffff" : "black",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = isDarkMode
                      ? "#555555"
                      : "#e0e0e0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = isDarkMode
                      ? "#444444"
                      : "#f0f0f0")
                  }
                >
                  {buttonText}
                </button>
                {/* Editor Component */}
                <Editor
                  markdown={markdown}
                  onChange={handleMarkdownChange}
                  isDarkMode={isDarkMode}
                />
              </div>
            </div>

            {/* Preview Section */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: isDarkMode ? "#2d2d2d" : "white",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "1em",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflow: "hidden",
                  transition: "background-color 0.3s",
                }}
              >
                {/* Preview Component */}
                <Preview
                  html={htmlPreview}
                  onApplyChanges={handleApplyChanges}
                  isDarkMode={isDarkMode}
                />
              </div>
            </div>
          </div>

          {/* Footer */}
          <footer
            style={{
              padding: "1em",
              textAlign: "center",
              backgroundColor: isDarkMode ? "#3a3a3a" : "#ebecf0",
              borderTop: "1px solid",
              borderTopColor: isDarkMode ? "#4a4a4a" : "#dfe1e6",
              display: "flex",
              justifyContent: "center",
              flexShrink: 0,
              transition: "background-color 0.3s, border-top-color 0.3s",
            }}
          >
            {/* Footer content */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <a
                  href='/privacy-policy'
                  style={{
                    margin: "0 10px",
                    color: isDarkMode ? "#4da6ff" : "#0052cc",
                    textDecoration: "none",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = isDarkMode
                      ? "#66b3ff"
                      : "#0066ff")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = isDarkMode
                      ? "#4da6ff"
                      : "#0052cc")
                  }
                >
                  Privacy Policy
                </a>
                <a
                  href='/terms-of-service'
                  style={{
                    margin: "0 10px",
                    color: isDarkMode ? "#4da6ff" : "#0052cc",
                    textDecoration: "none",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = isDarkMode
                      ? "#66b3ff"
                      : "#0066ff")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = isDarkMode
                      ? "#4da6ff"
                      : "#0052cc")
                  }
                >
                  Terms of Service
                </a>
              </div>
              <div
                style={{
                  color: isDarkMode ? "#cccccc" : "#5E6C84",
                }}
              >
                © 2024 Your Company. All rights reserved.
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default App;
