// Editor.js
import React from "react";

const Editor = ({ markdown, onChange, isDarkMode }) => {
  return (
    <textarea
      style={{
        width: "100%",
        height: "calc(100% - 40px)",
        padding: "10px",
        fontFamily: "monospace",
        fontSize: "1em",
        border: "1px solid",
        borderColor: isDarkMode ? "#555555" : "#dfe1e6",
        borderRadius: "4px",
        backgroundColor: isDarkMode ? "#1e1e1e" : "#f4f5f7",
        resize: "none",
        boxSizing: "border-box",
        color: isDarkMode ? "#ffffff" : "#000000",
        whiteSpace: "pre-wrap", // Ensure long lines wrap properly
        wordWrap: "break-word", // Prevent words from breaking out of the container
        transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
      }}
      value={markdown}
      onChange={(e) => onChange(e.target.value)} // Handle typing in the editor
      placeholder="Enter your markdown here..."
    />
  );
};

export default Editor;
